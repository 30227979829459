import { useUser } from "./useUser";
import { useSearchRecordsQuery } from "../state/api/record.api";
import { ServiceRecord } from "../types/ServiceRecord";

export const useOwnRecords = () => {
  const { user } = useUser();
  let ownRecords: ServiceRecord[]= [];

  const isOwnRecord = (rId: number | string | undefined) => {
    return ownRecords?.findIndex(r => r.id === Number(rId)) > -1;
  }

  if(!user) {
    return { ownRecords: [], isOwnRecord };
  }

  const { data } = useSearchRecordsQuery({
    limit: undefined,
    offset: 0,
    filter: {
      UserId: user.id
    },
    isOwner: true
  });
  ownRecords = data;

  return { ownRecords, isOwnRecord };
};
