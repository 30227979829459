import { OwnerTypes } from "../enum/ownerTypes.enum";
import { useUser } from "./useUser";
import { useSearchPartsQuery } from "../state/api/parts.api";
import { Part } from "../types/Part";

export const useOwnParts = () => {
  const { user } = useUser();
  let ownParts: Part[] = [];

  const isOwnPart = (pId: number | string | undefined) => {
    return ownParts?.findIndex(v => v.id === Number(pId)) > -1;
  }

  if(!user) {
    return { ownParts: [], isOwnPart };
  }

  const { data } = useSearchPartsQuery({
    limit: undefined,
    offset: 0,
    keywords: '',
    filter: {
      UserId: user.id
    },
    ownerType: OwnerTypes.My
  });

  ownParts = data;

  return { ownParts, isOwnPart };
};
