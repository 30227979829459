import { useSearchVehiclesQuery } from "../state/api/vehicle.api";
import { OwnerTypes } from "../enum/ownerTypes.enum";
import { useUser } from "./useUser";
import { Vehicle } from "../types/Vehicle";

export const useOwnVehicles = () => {
  const { user } = useUser();
  let ownVehicles: Vehicle[] = [];

  const isOwnVehicle = (vId: number | string | undefined) => {
    return ownVehicles?.findIndex(v => v.id === Number(vId)) > -1;
  }

  if(!user) {
    return { ownVehicles: [], isOwnVehicle };
  }

  const { data } = useSearchVehiclesQuery({
    limit: undefined,
    offset: 0,
    keywords: '',
    filter: {
      UserId: user.id
    },
    ownerType: OwnerTypes.My
  });
  ownVehicles = data;

  return { ownVehicles, isOwnVehicle };
};
